import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { ContentPlaceholder } from './ContentPlaceholder';
import { Example } from './Example';
interface MobileNavListInterface {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  options: Array<{ title: string }>;
  currentActiveItem: {
    itemId: string;
    isOpen: boolean;
  };
  setCurrentActiveItem: React.Dispatch<
    React.SetStateAction<{ itemId: string; isOpen: boolean }>
  >;
}
const MobileNavList = (props: MobileNavListInterface) => {
  const router = useRouter();
  const { open, setOpen, options } = {
    ...props,
  };
  const [stylingProperties, setStylingProperties] = useState({
    bgColor: 'purpleLight2',
    borderColor: 'purpleLight2',
  });

  const listenScrollEvent = () => {
    if (window.scrollY > 756) {
      setStylingProperties({ bgColor: 'white', borderColor: 'lightGrey3' });
    } else {
      setStylingProperties({
        bgColor: 'purpleLight2',
        borderColor: 'purpleLight2',
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const selfRedirectArray: string[] = [
    `${process.env.NEXT_PUBLIC_BASE_URL}`,
    '/who-can-use',
    '/examples',
    '/schedule-meeting',
    '/security',
    '/pricing',
  ];

  const resourceItems = [
    {
      icon: `${router.basePath}/assets/images/book.webp`,
      title: 'Blog',
      description: 'Lorem ipsum dolor sit amet, consectetur.',
      url: 'http://makewebbetter.com/blog',
    },
    {
      icon: `${router.basePath}/assets/images/zap.webp`,
      title: 'Knowledge Base',
      url: 'https://support.makewebbetter.com/hubspot-apps/category/hubspot-formpay-integration/',
      description: 'Lorem ipsum dolor sit amet, consectetur.',
    },
    {
      icon: `${router.basePath}/assets/images/users.webp`,
      title: 'Testimonials',
      description: 'Lorem ipsum dolor sit amet, consectetur.',
      url: 'https://ecosystem.hubspot.com/marketplace/apps/marketing/ecommerce/formpay-754041',
    },
    {
      icon: `${router.basePath}/assets/images/list.webp`,
      title: 'Form Examples',
      url: '/examples',
      description: 'Payment Form examples',
      altText: 'Form Examples',
    },
    {
      icon: `${router.basePath}/assets/images/calender-icon.svg`,
      title: 'Book Demo',
      url: '/schedule-meeting',
      description: 'Book a Demo Meeting with FormPay',
      altText: 'Form Examples',
    },
    {
      icon: `${router.basePath}/assets/images/file.webp`,
      title: 'Documentation',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
      url: 'https://docs.makewebbetter.com/formpay-hubspot-integration/',
    },
  ];
  const companyItems = [
    {
      icon: `${router.basePath}/assets/images/phoneBlueIcon.webp`,
      title: 'Contact',
      description: 'Lorem ipsum dolor sit amet, consectetur.',
      url: 'https://makewebbetter.com/contact-us/',
    },
    {
      icon: `${router.basePath}/assets/images/lock.webp`,
      title: 'Security',
      description: 'Lorem ipsum dolor sit amet, consectetur.',
      url: '/security',
    },
  ];
  const accordionIds = [
    {
      title: 'Resources',
      id: 'resources',
      content: <ContentPlaceholder items={resourceItems} />,
    },
    {
      title: 'Company',
      id: 'company',
      content: <ContentPlaceholder items={companyItems} />,
    },
  ];

  return (
    <section
      className={
        open
          ? 'tw-absolute tw-inset-x-0 tw-top-0 tw-origin-top-right tw-scale-100 tw-bg-white tw-opacity-100 tw-transition tw-duration-200 tw-ease-out lg:tw-hidden'
          : 'tw-absolute tw-inset-x-0 tw-top-0 tw-origin-top-right tw-scale-95 tw-p-2 tw-opacity-0 tw-transition lg:tw-hidden'
      }
    >
      {open && (
        <section className=' tw-divide-y-2 tw-divide-gray-50 tw-bg-white'>
          <section className=''>
            <section
              className={`tw-bg-${stylingProperties?.bgColor} tw-flex tw-items-center tw-justify-between tw-p-6`}
            >
              <section role='img' aria-label='FormPay logo'>
                <Image
                  alt='FormPay logo'
                  src={`${router.basePath}/assets/images/Logo.svg`}
                  width={114}
                  height={41}
                />
              </section>
              <section className='tw-mr-2 tw-flex'>
                <section className='tw-mr-4 tw-hidden md:tw-flex'>
                  <button
                    tabIndex={0}
                    className='tw-mr-6 tw-bg-purpleLight2 tw-px-4 tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-formPay100'
                    onClick={() => {
                      router.push('/auth/login');
                    }}
                  >
                    Log in
                  </button>
                  <button
                    tabIndex={0}
                    className='tw-mr-6 tw-rounded-[8px] tw-border  tw-border-solid tw-border-formPay100 tw-bg-formPay100 tw-px-4 tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-white tw-shadow-[0_1px_2px_rgba(16,24,40,0.05)]'
                    onClick={() => {
                      router.push('/auth/register');
                    }}
                  >
                    Sign up
                  </button>
                </section>
                <button
                  type='button'
                  className='tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2'
                  onClick={() => setOpen(!open)}
                >
                  <span className='tw-sr-only'>Close menu</span>
                  <svg
                    className='tw-h-6 tw-w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </section>
            </section>
            <section className=' tw-bg-white tw-p-6'>
              <nav className='tw-grid tw-gap-y-8'>
                {options.map((item: any, i: number) =>
                  !['resources', 'company'].includes(item?.id) ? (
                    <Link
                      href={`${item?.url}`}
                      key={i}
                      target={`${
                        selfRedirectArray.includes(item?.url)
                          ? '_self'
                          : '_blank'
                      }`}
                    >
                      <h1
                        key={i}
                        className='tw-flex tw-items-center tw-rounded-md'
                      >
                        <span className='tw-ml-3 tw-font-fontMedium tw-text-normalGrey'>
                          {item?.title}
                        </span>
                      </h1>
                    </Link>
                  ) : (
                    <></>
                  )
                )}

                <Example accordionIds={accordionIds} />
              </nav>
              <section className='md:tw-hidden'>
                <button
                  tabIndex={0}
                  className='tw-my-2 tw-mr-6 tw-w-full  tw-px-4 tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-formPay100'
                  onClick={() => {
                    router.push('/auth/login');
                  }}
                >
                  Log in
                </button>
                <button
                  tabIndex={0}
                  className='tw-rounded-2 tw-my-2 tw-mr-6 tw-w-full tw-bg-formPay100 tw-px-4  tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-white'
                  onClick={() => {
                    router.push('/auth/register');
                  }}
                >
                  Sign up
                </button>
              </section>
            </section>
          </section>
        </section>
      )}
    </section>
  );
};
export default MobileNavList;
