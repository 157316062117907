import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useState } from 'react';
interface AccordionInterface {
  i: string | React.ReactNode;
  key: number;
  uniqueKey: number;
  expanded: string | React.ReactNode;
  setExpanded: React.Dispatch<React.SetStateAction<string | React.ReactNode>>;
  content: React.ReactNode;
  isDivider?: boolean;
  dynamicIcons?: {
    open: string;
    close: string;
  };
}
const Accordion = ({
  i,
  uniqueKey,
  expanded,
  setExpanded,
  content,
  isDivider,
  dynamicIcons,
}: AccordionInterface) => {
  const isOpen = i === expanded;
  const router = useRouter();

  return (
    <>
      <motion.section
        key={uniqueKey}
        initial={false}
        className='tw-h-auto tw-w-full'
        onClick={() => setExpanded(isOpen ? '' : i)}
      >
        <motion.section className='tw-flex tw-justify-between' tabIndex={0}>
          <motion.section className='tw-flex tw-items-center tw-rounded-md'>
            <motion.section className='tw-ml-3  tw-font-fontLight tw-text-normalGrey'>
              {i}
            </motion.section>
          </motion.section>
          {!isOpen && (
            <Image
              alt='down arrow'
              src={
                dynamicIcons
                  ? dynamicIcons?.open
                  : `${router.basePath}/assets/images/chevron-down.webp`
              }
              width={20}
              height={24}
              className='tw-h-[24px] tw-w-[20px]'
            />
          )}
          {isOpen && (
            <Image
              alt='down arrow'
              src={
                dynamicIcons
                  ? dynamicIcons?.close
                  : `${router.basePath}/assets/images/Chevron-up.svg`
              }
              width={20}
              height={24}
              className='tw-h-[24px] tw-w-[20px]'
            />
          )}
        </motion.section>
      </motion.section>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            className='tw-mx-4 tw-my-0 '
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            tw-transition={{ duration: 0.2 }}
          >
            {content}
          </motion.section>
        )}
      </AnimatePresence>

      {isDivider && (
        <section className='tw-h-[1px] tw-w-full tw-bg-lightGrey2'></section>
      )}
    </>
  );
};
interface AccordionItem {
  title: React.ReactNode | string | undefined;
  id: string;
  content: React.ReactNode;
}
interface ExampleInterface {
  accordionIds: Array<AccordionItem>;
  isDivider?: boolean;
  dynamicIcons?: {
    open: string;
    close: string;
  };
}
export const Example = (props: ExampleInterface) => {
  const { accordionIds, isDivider, dynamicIcons } = { ...props };
  const [expanded, setExpanded] = useState('');

  return (
    <>
      {accordionIds.map((item: AccordionItem, i) => (
        <Accordion
          i={item.title}
          uniqueKey={i}
          key={i}
          dynamicIcons={dynamicIcons}
          isDivider={isDivider}
          expanded={expanded}
          setExpanded={setExpanded}
          content={item?.content}
        />
      ))}
    </>
  );
};
