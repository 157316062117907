import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession as useNextSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';

import MobileNavList from './MobileNavList';
import NavList from './NavList';
const Header = () => {
  const router = useRouter();
  const { data: session } = useNextSession();
  const [options] = React.useState([
    { title: 'Home', id: 'home', url: `${process.env.NEXT_PUBLIC_BASE_URL}` },
    { title: 'Who can use?', id: 'who-can-use', url: '/who-can-use' },
    {
      title: 'Resources',
      id: 'resources',
      childNodes: [
        { title: 'Child text 1', id: 'resources-1' },
        { title: 'Child text 2', id: 'resources-2' },
      ],
    },
    {
      title: 'Company',
      id: 'company',
      childNodes: [
        { title: 'Child text 1', id: 'company-1' },
        { title: 'Child text 2', id: 'company-2' },
      ],
    },
    { title: 'Pricing', id: 'pricing', url: '/pricing' },
  ]);
  const [open, setOpen] = React.useState(false);
  const [currentActiveItem, setCurrentActiveItem] = React.useState({
    itemId: 'home',
    isOpen: false,
  });
  const [stylingProperties, setStylingProperties] = useState({
    bgColor: 'purpleLight2',
    borderColor: 'purpleLight2',
  });
  const [set, isSet] = useState(false);
  const listenScrollEvent = () => {
    if (window.scrollY > 756) {
      setStylingProperties({ bgColor: 'white', borderColor: 'lightGrey3' });
      isSet(true);
    } else {
      setStylingProperties({
        bgColor: 'purpleLight2',
        borderColor: 'purpleLight2',
      });
      isSet(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <section
      className={`tw-bg-${stylingProperties?.bgColor} ${
        set ? 'is-sticky' : 'is-not-sticky'
      } tw-fixed tw-top-0 tw-z-[10000] tw-h-[80px] tw-w-full lg:tw-h-[90px]`}
    >
      <section
        className={`tw-bg-${stylingProperties?.bgColor}  lg:tw-border-${stylingProperties?.borderColor}  tw-mx-[11px]  tw-max-w-[1024px]   tw-px-3 sm:tw-px-6 lg:tw-mt-[11px] lg:tw-max-w-[1200px] lg:tw-rounded-[50px] lg:tw-border-[1px] xl:tw-mx-auto`}
      >
        <section className='tw-flex tw-items-center tw-justify-between tw-py-6 lg:tw-justify-start lg:tw-space-x-10'>
          <section className='tw-flex'>
            <section
              className='tw-flex tw-justify-start'
              role='img'
              aria-label='FormPay logo'
            >
              <Link
                href={`${process.env.NEXT_PUBLIC_BASE_URL}`}
                className='tw-mt-2'
              >
                <Image
                  src={`${router.basePath}/assets/images/FormPay-logo.svg`}
                  width={114}
                  height={41}
                  alt='FormPay Logo'
                  tabIndex={0}
                />
              </Link>
            </section>

            <nav className='tw-ml-16 tw-hidden lg:tw-flex '>
              <NavList
                options={options}
                currentActiveItem={currentActiveItem}
                setCurrentActiveItem={setCurrentActiveItem}
              />
            </nav>
          </section>
          <section className='lg:tw-hidden' onClick={() => setOpen(!open)}>
            {!open && (
              <Image
                alt='read post'
                src={`${router.basePath}/assets/images/hamburger.svg`}
                width={18}
                height={12}
                className='tw-h-[12px] tw-w-[18px]'
              />
            )}
          </section>
          <section className='tw-hidden tw-items-center tw-justify-end lg:tw-flex lg:tw-w-0 lg:tw-flex-1'>
            <Link href='/auth/login'>
              <button
                tabIndex={0}
                className='tw-mr-6 tw-px-4 tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-lightGrey hover:tw-font-fontBold hover:tw-text-activenormalGrey'
              >
                {session ? `Go to dashboard` : `Log in`}
              </button>
            </Link>
            {session ? (
              <Link href='/logout'>
                <button
                  tabIndex={0}
                  className='tw-bg-purpleLight tw-px-4  tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-purpleDark hover:tw-font-fontBold hover:tw-text-activenormalGrey'
                >
                  Log Out
                </button>
              </Link>
            ) : (
              <Link href='/auth/register'>
                <button
                  tabIndex={0}
                  className='tw-bg-purpleLight tw-px-4  tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-purpleDark hover:tw-font-fontBold hover:tw-text-activenormalGrey'
                >
                  Sign up
                </button>
              </Link>
            )}
          </section>
        </section>
      </section>

      <MobileNavList
        options={options}
        open={open}
        setOpen={setOpen}
        currentActiveItem={currentActiveItem}
        setCurrentActiveItem={setCurrentActiveItem}
      />
    </section>
  );
};
export default Header;
