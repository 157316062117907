import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
interface NavListInterface {
  options: Array<{ title: string; url?: string }>;
  currentActiveItem: {
    itemId: string;
    isOpen: boolean;
  };
  setCurrentActiveItem: React.Dispatch<
    React.SetStateAction<{ itemId: string; isOpen: boolean }>
  >;
}
const NavList = (props: NavListInterface) => {
  const { currentActiveItem, setCurrentActiveItem, options } = { ...props };
  const ref = useRef<HTMLElement>(null);
  const companyRef = useRef<HTMLElement>(null);
  const router = useRouter();

  const tempFun = () => {
    if (router.pathname === '/') {
      return ['home'];
    } else {
      return [router.pathname.slice(1, router.pathname.length)];
    }
  };
  const tempClass = tempFun();
  const selfRedirectArray: string[] = [
    `${process.env.NEXT_PUBLIC_BASE_URL}`,
    '/who-can-use',
    '/examples',
    '/schedule-meeting',
    '/security',
    '/pricing',
  ];

  useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // Clicked outside the ref element
        setCurrentActiveItem({
          itemId: 'resources',
          isOpen: false,
        });
      }
      if (event.target != null && event.target.innerText === 'Resources') {
        setCurrentActiveItem({
          itemId: 'resources',
          isOpen: true,
        });
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        companyRef.current &&
        !companyRef.current.contains(event.target as Node)
      ) {
        // Clicked outside the ref element
        setCurrentActiveItem({
          itemId: 'company',
          isOpen: false,
        });
      }
      if (event.target != null && event.target.innerText === 'Company') {
        setCurrentActiveItem({
          itemId: 'company',
          isOpen: true,
        });
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [companyRef]);

  const ResourceContent = (id: string) => {
    const resourceItems = [
      {
        icon: `${router.basePath}/assets/images/book.webp`,
        title: 'Blog',
        description: 'Learn Better with MakeWebBetter Blogs',
        url: 'http://makewebbetter.com/blog',
        altText: 'Blog',
      },
      {
        icon: `${router.basePath}/assets/images/zap.webp`,
        title: 'Knowledge Base',
        url: 'https://support.makewebbetter.com/hubspot-apps/category/hubspot-formpay-integration/',
        description: 'Details regarding hubspot FormPay integration',
        altText: 'Knowledge Base',
      },
      {
        icon: `${router.basePath}/assets/images/users.webp`,
        title: 'Testimonials',
        description: 'What users think about FormPay',
        url: 'https://ecosystem.hubspot.com/marketplace/apps/marketing/ecommerce/formpay-754041',
        altText: 'Testimonials',
      },
      {
        icon: `${router.basePath}/assets/images/list.webp`,
        title: 'Form Examples',
        url: '/examples',
        description: 'Payment Form examples',
        altText: 'Form Examples',
      },
      {
        icon: `${router.basePath}/assets/images/calender-icon.svg`,
        title: 'Book Demo',
        url: '/schedule-meeting',
        description: 'Book a Demo Meeting with FormPay',
        altText: 'Form Examples',
      },
      {
        icon: `${router.basePath}/assets/images/file.webp`,
        title: 'Documentation',
        description: 'FormPay hubspot integration related documentation',
        url: 'https://docs.makewebbetter.com/hubspot-formpay-integration/',
        altText: 'Documentation',
      },
    ];

    return (
      <section className='tw-relative'>
        <button
          type='button'
          className={`
          tw-group tw-inline-flex  tw-items-center tw-rounded-md
              `}
          onClick={() =>
            setCurrentActiveItem({
              itemId: 'resources',
              isOpen: !currentActiveItem?.isOpen,
            })
          }
        >
          <h1
            className={`${
              currentActiveItem?.itemId !== id || !currentActiveItem?.isOpen
                ? ' tw-font-fontMedium tw-text-normalGrey'
                : ' tw-font-fontBold tw-text-activenormalGrey'
            }`}
          >
            Resources
          </h1>
          <svg
            className={
              currentActiveItem?.itemId === 'resources' &&
              currentActiveItem?.isOpen
                ? 'tw-ml-2 tw-h-5 tw-w-5 tw-rotate-180 tw-text-normalGrey tw-transition tw-duration-200 tw-ease-out'
                : 'tw-ml-2 tw-h-5 tw-w-5 tw-rotate-0 tw-text-normalGrey tw-transition tw-duration-200 tw-ease-out'
            }
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        </button>

        <section
          className={
            currentActiveItem?.itemId === 'resources' &&
            currentActiveItem?.isOpen
              ? 'tw-absolute tw-z-10 tw-ml-4 tw-mt-3 tw-w-[520px] tw-translate-y-0 tw-bg-[#FFFFFF] tw-px-2 tw-opacity-100 tw-transition tw-duration-200 tw-ease-out sm:tw-px-0 lg:tw-left-1/2 lg:tw-ml-0 lg:tw--translate-x-1/2'
              : ' tw-absolute tw-z-10 tw-ml-4 tw-mt-3 tw-w-[520px] tw-max-w-md tw--translate-y-1 tw-px-2 tw-opacity-0 sm:tw-px-0 lg:tw-left-1/2 lg:tw-ml-0 lg:tw--translate-x-1/2'
          }
        >
          {currentActiveItem?.itemId === 'resources' &&
          currentActiveItem?.isOpen ? (
            <section className='tw-max-h-[370px] tw-overflow-hidden tw-border-[1px] tw-border-solid tw-border-[#E0E1E3] tw-p-6'>
              <section className={` tw-grid tw-grid-cols-12`}>
                <section
                  ref={ref}
                  className={`tw-flex tw-flex-col sm:tw-col-span-12 md:tw-col-span-12 lg:tw-col-span-12`}
                >
                  <h1
                    tabIndex={0}
                    className='!tw-mb-4 !tw-mt-8  tw-text-[14px] tw-text-[#7936f4]'
                  >
                    Resources
                  </h1>
                  <section className='tw-flex'>
                    <section className='tw-flex tw-h-auto tw-flex-col'>
                      {resourceItems.slice(0, 3).map((item, i) => (
                        <Link
                          href={`${item?.url}`}
                          key={item?.url}
                          target={`${
                            selfRedirectArray.includes(item?.url)
                              ? '_self'
                              : '_blank'
                          }`}
                        >
                          <section
                            className='tw-my-4 tw-flex tw-max-w-[190px] lg:tw-max-w-[250px]'
                            key={i}
                          >
                            <section
                              role='img'
                              aria-label='blog item'
                              tabIndex={0}
                            >
                              <Image
                                alt={item?.altText}
                                src={item?.icon}
                                width={22}
                                height={20}
                                className='tw-h-[20px] tw-w-[22px]'
                              />{' '}
                            </section>
                            <h1 className='tw-ml-2 tw-pl-4 tw-pt-2  tw-text-normalText tw-font-fontMedium tw-text-darkerGrey'>
                              <div tabIndex={0}> {item?.title}</div>
                              <br />

                              <span
                                tabIndex={0}
                                className='tw-mt-2  tw-text-smallText tw-font-fontLight tw-leading-[20px] tw-text-lightBlack'
                              >
                                {item?.description}
                              </span>
                            </h1>
                          </section>
                        </Link>
                      ))}
                    </section>
                    <section className='tw-mx-auto tw-flex tw-h-auto tw-flex-col'>
                      {resourceItems
                        .slice(3, resourceItems.length)
                        .map((item, i) => (
                          <Link
                            href={`${item?.url}`}
                            key={item?.url}
                            target={`${
                              selfRedirectArray.includes(item?.url)
                                ? '_self'
                                : '_blank'
                            }`}
                          >
                            <section
                              className='tw-my-4 tw-flex tw-max-w-[190px] lg:tw-max-w-[250px]'
                              key={i}
                            >
                              <section
                                tabIndex={0}
                                role='img'
                                aria-label='blog item'
                              >
                                <Image
                                  alt={item?.altText}
                                  src={item?.icon}
                                  width={22}
                                  height={20}
                                  className='tw-h-[20px] tw-w-[22px]'
                                />{' '}
                              </section>
                              <h1 className='tw-ml-2 tw-pl-4 tw-pt-2  tw-text-normalText tw-font-fontMedium tw-text-darkerGrey'>
                                <div tabIndex={0}>{item?.title}</div>
                                <br />
                                <span
                                  tabIndex={0}
                                  className='tw-mt-2  tw-text-smallText tw-font-fontLight tw-leading-[20px] tw-text-lightBlack'
                                >
                                  {item?.description}
                                </span>
                              </h1>
                            </section>
                          </Link>
                        ))}
                    </section>
                  </section>
                </section>
              </section>
            </section>
          ) : (
            <></>
          )}
        </section>
      </section>
    );
  };
  const CompanyContent = (id: string) => {
    const companyItems = [
      {
        icon: `${router.basePath}/assets/images/phoneBlueIcon.webp`,
        title: 'Contact',
        description: 'Need an Expert Hand for Your Potential Project?',
        url: 'https://makewebbetter.com/contact-us/',
        altText: 'Contact Us',
      },
      {
        icon: `${router.basePath}/assets/images/lock.webp`,
        title: 'Security',
        description: 'Payment Security Compliances',
        url: '/security',
        altText: 'Security',
      },
    ];

    return (
      <section className='tw-relative'>
        <button
          type='button'
          className={`
          tw-group tw-inline-flex  tw-items-center tw-rounded-md
              `}
          onClick={() =>
            setCurrentActiveItem({
              itemId: 'company',
              isOpen: !currentActiveItem?.isOpen,
            })
          }
        >
          <h1
            className={`${
              currentActiveItem?.itemId !== id || !currentActiveItem?.isOpen
                ? ' tw-font-fontMedium tw-text-normalGrey'
                : ' tw-font-fontBold tw-text-activenormalGrey'
            }`}
          >
            Company
          </h1>
          <svg
            className={
              currentActiveItem?.itemId === 'company' &&
              currentActiveItem?.isOpen
                ? 'tw-ml-2 tw-h-5 tw-w-5 tw-rotate-180 tw-text-normalGrey tw-transition tw-duration-200 tw-ease-out '
                : 'tw-ml-2 tw-h-5 tw-w-5 tw-text-normalGrey '
            }
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        </button>

        <section
          className={
            currentActiveItem?.itemId === 'company' && currentActiveItem?.isOpen
              ? 'tw-absolute tw-z-10 tw-ml-4 tw-mt-3 tw-w-[280px] tw--translate-y-0 tw-bg-[#FFFFFF] tw-px-2 tw-opacity-100 tw-transition tw-duration-200 tw-ease-out sm:tw-px-0 lg:tw-left-1/2 lg:tw-ml-0 lg:tw--translate-x-1/2'
              : ' tw-absolute tw-z-10 tw-ml-4 tw-mt-3 tw-w-[25vw] tw--translate-y-1 tw-px-2 tw-opacity-0 sm:tw-px-0 lg:tw-left-1/2 lg:tw-ml-0 lg:tw--translate-x-1/2'
          }
        >
          {currentActiveItem?.itemId === 'company' &&
          currentActiveItem?.isOpen ? (
            <section className='tw-h-auto tw-overflow-hidden tw-border-[1px] tw-border-solid tw-border-[#E0E1E3] tw-p-6'>
              <section className={` tw-grid tw-grid-cols-12`}>
                <section
                  ref={companyRef}
                  className={`tw-flex tw-flex-col sm:tw-col-span-12 md:tw-col-span-12 lg:tw-col-span-12`}
                >
                  <h1
                    tabIndex={0}
                    className='!tw-mb-4 !tw-mt-8  tw-text-smallText tw-text-[#7936f4]'
                  >
                    Company
                  </h1>
                  <section className='tw-flex'>
                    <section className='tw-flex tw-h-[150px] tw-flex-col tw-justify-start'>
                      {companyItems.map((item, i) => (
                        <Link
                          href={item?.url}
                          key={i}
                          target={`${
                            selfRedirectArray.includes(item?.url)
                              ? '_self'
                              : '_blank'
                          }`}
                        >
                          <section
                            className='tw-my-4 tw-flex tw-w-[250px]'
                            key={i}
                          >
                            <section
                              tabIndex={0}
                              role='img'
                              aria-label='blog item'
                            >
                              <Image
                                alt={item?.altText}
                                src={item?.icon}
                                width={22}
                                height={20}
                                className='tw-h-[20px] tw-w-[22px]'
                              />{' '}
                            </section>
                            <h1 className='tw-ml-2 tw-pl-4 tw-pt-2  tw-text-normalText tw-font-fontMedium tw-text-darkerGrey'>
                              <div tabIndex={0}>{item?.title}</div>
                              <br />
                              <span
                                tabIndex={0}
                                className='tw-mt-2  tw-text-smallText tw-font-fontLight tw-leading-[20px] tw-text-lightBlack'
                              >
                                {item?.description}
                              </span>
                            </h1>
                          </section>
                        </Link>
                      ))}
                    </section>
                  </section>
                </section>
              </section>
            </section>
          ) : (
            <></>
          )}
        </section>
      </section>
    );
  };

  return (
    <>
      {options.map((item: any, i: number) => {
        return item.id === 'resources' ? (
          <h1
            key={i}
            tabIndex={0}
            className={`!tw-mx-4 tw-flex tw-items-center tw-p-4 ${
              currentActiveItem?.itemId !== item?.id
                ? 'tw-font-fontMedium tw-text-normalGrey'
                : 'tw-font-fontBold tw-text-activenormalGrey'
            } tw-text-smallText tw-not-italic  tw-leading-4 hover:tw-font-fontBold hover:tw-text-activenormalGrey`}
          >
            {ResourceContent(item?.id)}
          </h1>
        ) : item.id === 'company' ? (
          <h1
            key={i}
            tabIndex={0}
            className={`!tw-mx-4 tw-flex tw-items-center tw-p-4 ${
              currentActiveItem?.itemId === item?.id
                ? 'tw-font-fontBold tw-text-activenormalGrey'
                : 'tw-font-fontMedium tw-text-normalGrey'
            } tw-text-smallText tw-not-italic  tw-leading-4 hover:tw-font-fontBold hover:tw-text-activenormalGrey`}
          >
            {CompanyContent(item?.id)}
          </h1>
        ) : (
          <h1
            key={i}
            tabIndex={0}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              currentActiveItem?.itemId !== item?.id
                ? setCurrentActiveItem({ itemId: item?.id, isOpen: false })
                : setCurrentActiveItem({ itemId: '', isOpen: false });
            }}
            className={`!tw-mx-4 tw-flex tw-cursor-pointer tw-items-center tw-p-4 `}
          >
            <Link
              href={item?.url}
              key={i}
              target={`${
                selfRedirectArray.includes(item?.url) ? '_self' : '_target'
              }`}
              className={` ${
                tempClass.includes(item?.id)
                  ? 'tw-font-fontBold tw-text-activenormalGrey'
                  : 'tw-font-fontMedium tw-text-normalGrey'
              } tw-text-smallText tw-not-italic  tw-leading-4 hover:tw-font-fontBold hover:tw-text-activenormalGrey`}
            >
              {item?.title}
            </Link>
          </h1>
        );
      })}
    </>
  );
};
export default NavList;
