import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';
interface ContentPlaceholderInterface {
  items: Array<any>;
}
export const ContentPlaceholder = (props: ContentPlaceholderInterface) => {
  const { items } = { ...props };
  const selfRedirectArray: string[] = [
    `${process.env.NEXT_PUBLIC_BASE_URL}`,
    '/who-can-use',
    '/examples',
    '/schedule-meeting',
    '/security',
    '/pricing',
  ];

  return (
    <motion.section
      variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
      tw-transition={{ duration: 0.2 }}
    >
      {items.map(item => (
        <Link
          href={`${item?.url}`}
          key={item?.url}
          target={`${
            selfRedirectArray.includes(item?.url) ? '_self' : '_blank'
          }`}
        >
          <section className='tw-my-8 tw-flex' key={item?.title}>
            <section role='img' aria-label='blog item'>
              <Image alt='blog menu' src={item?.icon} width={22} height={24} />{' '}
            </section>
            <section className='tw-text-4 tw-ml-4 tw-font-fontMedium '>
              {item?.title}
            </section>
          </section>
        </Link>
      ))}
    </motion.section>
  );
};
